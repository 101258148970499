import React from 'react';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { fadeIn, textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';

const Personal = () => {
  return (
    <div className='-mt-[4rem]'>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubTextLight}>Personal Life</p>
        <h2 className={styles.sectionHeadTextLight}>About Me.</h2>
      </motion.div>

      <motion.p
        variants={fadeIn('', '', 0.1, 1)}
        className='mt-4 text-black text-[18px] max-w-3xl leading-[30px]'>
        I'm a curious soul with an insatiable appetite for exploration and
        learning. I thrive on discovering new cultures, langauges and
        experiences. I find immense joy in traveling, (ask me about Barcelona)
        whether it's immersing myself in the bustling streets of a vibrant city
        or through serene landscapes. Every journey fuels my curiosity and
        broadens my perspective, reminding me of the boundless beautiful
        diversity our planet has to offer. <br />
        <br />
        Speaking of diversity, my love for languages is deeply ingrained in my
        being. Fluent in English and Haitian Creole, I'm currently on a journey
        to master Spanish and French, driven by my belief in the power of
        language to bridge gaps and connect hearts across cultures. Every word I
        learn opens up a new window to understanding and appreciation for
        different ways of life.
      </motion.p>
    </div>
  );
};

export default SectionWrapper(Personal, 'personal');
