import { BrowserRouter } from 'react-router-dom';

import Navbar from './components/Navbar';

import './index.css';
import Hero from './components/Hero';
import About from './components/About';
import Tech from './components/Tech';
import Experience from './components/Experience';
import Personal from './components/Personal';
import Projects from './components/Projects';

function App() {
  return (
    <BrowserRouter>
      <div className='relative z-0'>
        <div>
          <Navbar />
          <Hero />
        </div>
        <div className='bg-about bg-cover bg-center bg-no-repeat'>
          <About />
        </div>

        <div className='bg-tech bg-cover bg-center bg-no-repeat pb-10'>
          <Tech />
        </div>

        <Projects />

        <div
          className='bg-experience bg-cover bg-center bg-no-repeat 
            rounded-tl-[150px] rounded-br-[150px]'>
          <div
            className='bg-experienceLight bg-cover bg-center 
            bg-no-repeat rounded-tl-[150px] rounded-br-[130px]'>
            <Experience />
          </div>
        </div>

        <div className='bg-about bg-cover bg-center bg-no-repeat pb-10'>
          <Personal />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
