import {
  frontend,
  backend,
  reactjs,
  html,
  css,
  typescript,
  javascript,
  nodejs,
  python,
  java,
  django,
  redux,
  graphql,
  git,
  dxc,
  truework,
  voltaiq,
  figma,
  wordsnake,
  audioPlayer,
} from '../assets';

export const navLinks = [
  {
    id: 'about',
    title: 'Overview',
  },
  {
    id: 'work',
    title: 'Experience',
  },
  {
    id: 'personal',
    title: 'About',
  },
];

const services = [
  {
    title: 'Frontend Developer',
    icon: frontend,
  },
  {
    title: 'Backend Developer',
    icon: backend,
  },
];

const technologies = [
  {
    name: 'HTML 5',
    icon: html,
  },
  {
    name: 'CSS 3',
    icon: css,
  },
  {
    name: 'JavaScript',
    icon: javascript,
  },
  {
    name: 'TypeScript',
    icon: typescript,
  },
  {
    name: 'React JS',
    icon: reactjs,
  },
  {
    name: 'Python',
    icon: python,
  },
  {
    name: 'Java',
    icon: java,
  },
  {
    name: 'Django',
    icon: django,
  },
  {
    name: 'Redux Toolkit',
    icon: redux,
  },
  {
    name: 'Node JS',
    icon: nodejs,
  },
  {
    name: 'Graphql',
    icon: graphql,
  },
  {
    name: 'Figma',
    icon: figma,
  },
  {
    name: 'Git',
    icon: git,
  },
];

const experiences = [
  {
    title: 'Software Engineer II',
    company_name: 'Truework',
    icon: truework,
    iconBg: '#333333',
    date: 'Sept 2022 - ',
  },
  {
    title: 'Software Engineer',
    company_name: 'Voltaiq',
    icon: voltaiq,
    iconBg: '#333333',
    date: 'April 2021 - July 2022',
  },
  {
    title: 'Associate Software Engineer',
    company_name: 'DXC Technology',
    icon: dxc,
    iconBg: '#333333',
    date: 'July 2017 - March 2021',
  },
];

const projects = [
  {
    id: 'project-1',
    name: 'Audio Player',
    description:
      'Created an audio player and visualizer to familiar myself with how Web Audio API audio-processing',
    tags: [{ name: 'React.js', color: 'blue-text-gradient' }],
    image: audioPlayer,
    repo: 'https://github.com/JohnCS94/audio-player',
    demo: 'https://audio-player-seven-smoky.vercel.app/',
  },
  {
    id: 'project-2',
    name: 'WordSnake',
    description: 'A mobile cross over between snake and scrabble.',
    tags: [
      {
        name: 'Android Studio',
        color: 'blue-text-gradient',
      },
      {
        name: 'C#',
        color: 'green-text-gradient',
      },
    ],
    image: wordsnake,
    repo: 'https://github.com/JohnCS94/Word-Snake',
    demo: 'https://play.google.com/store/apps/details?id=com.Scipio.WordSnake',
  },
];

export { services, technologies, experiences, projects };
