import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { navLinks } from '../constants';

import { logo, close, menu } from '../assets';

const Navbar = () => {
  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(false);

  return (
    <nav className='bg-slate-100'>
      <div className='w-full flex bg-slate-100 justify-between items-center max-w-7xl mx-auto'>
        <Link
          to='/'
          className='flex items-center gap-2'
          onClick={() => {
            setActive('');
            window.scrollTo(0, 0);
          }}>
          <img
            src={logo} // your logo comes here
            alt='logo'
            className='sm:w-[50px] sm:h-[50px] w-[45px] h-[45px] object-contain'
          />
        </Link>
        <ul className='list-none hidden sm:flex flex-row gap-14 mt-2'>
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? 'text-french' : 'text-eerieBlack'
              } hover:text-taupe text-[21px] font-medium font-mova 
                uppercase tracking-[3px] cursor-pointer nav-links`}
              onClick={() => setActive(nav.title)}>
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>

        {/* mobile */}
        <div className='sm:hidden flex flex-1 w-screen justify-end items-center'>
          {toggle ? (
            <div
              className={`p-6 bg-flashWhite opacity-[0.98] absolute 
                top-0 left-0 w-screen h-[100vh] z-10 menu ${
                  toggle ? 'menu-open' : 'menu-close'
                }`}>
              <div className='flex justify-end'>
                <img
                  src={close}
                  alt='close'
                  className='w-[22px] h-[22px] object-contain cursor-pointer'
                  onClick={() => setToggle(!toggle)}
                />
              </div>
              <ul
                className='list-none flex flex-col -gap-[1rem] 
                items-start justify-end mt-[10rem] -ml-[35px]'>
                {navLinks.map((nav) => (
                  <li
                    id={nav.id}
                    key={nav.id}
                    className={`${
                      active === nav.title ? 'text-white' : 'text-eerieBlack'
                    } text-[88px] font-bold font-arenq 
                      uppercase tracking-[1px] cursor-pointer`}
                    onClick={() => {
                      setToggle(!toggle);
                      setActive(nav.title);
                    }}>
                    <a href={`#${nav.id}`}>{nav.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <img
              src={menu}
              alt='menu'
              className='w-[34px] h-[34px] object-contain cursor-pointer'
              onClick={() => setToggle(!toggle)}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
